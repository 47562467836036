<template>
  <layout width="420">
    <template slot="header">
      Edit States
    </template>

    <template>
      <v-form>
        <template v-for="(objectState, index) in state.objectStates">
          <form-subheader
            :key="`header_${index}`"
            :title="objectState.infoName.value"
          />
          <div :key="`alert_${index}`">
            <v-select
              v-model="objectState.sourceId"
              label="Source"
              :items="sources"
              item-value="id"
              item-text="name"
              clearable
            />
            <v-select
              v-model="objectState.stateValue.linkedPropertyId"
              label="Property"
              item-value="id"
              item-text="humanReadable"
              :items="properties(objectState.sourceId)"
              clearable
            />
            <div class="d-flex flex-nowrap">
              <v-select
                v-model="objectState.stateCondition.value.operator"
                :items="operators"
                class="mr-4"
                clearable
              />
              <v-text-field v-model="objectState.stateCondition.value.value" />
            </div>
          </div>
        </template>
      </v-form>
    </template>
    <template slot="footer">
      <v-spacer />
      <v-btn text color="text-primary" @click.stop="$emit('close')">
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        depressed
        :loading="loading"
        @click.stop="submit"
      >
        Save
      </v-btn>
    </template>
  </layout>
</template>

<script>
import Layout from '@/components/popup/PopupLayoutDefault';
import { ref, onBeforeMount } from '@vue/composition-api';
import { usePromise } from 'vue-composable';
import { statesService } from '@/modules/objects/api';
import { prepareObject } from '@/provider/utils';
import { linkedObjectsService } from '@/modules/linked-objects/api';
import { objectPropertyService } from '@/modules/common/api';
import sortBy from 'lodash.sortby';

export default {
  name: 'ObjectStatesEdit',
  components: {
    Layout
  },
  props: {
    objectId: {
      type: String,
      default: ''
    },
    objectStateId: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const operators = ['>', '<', '=', '!=', 'contains'];
    const state = ref({
      objectStates: []
    });

    const sources = ref([]);

    const handleSubmit = async () => {
      const promises = [];

      state.value.objectStates.forEach(state => {
        promises.concat([
          objectPropertyService.update(state.stateValue.id, {
            linkedPropertyId: state.stateValue.linkedPropertyId
          }),
          objectPropertyService.update(state.stateCondition.id, {
            value: state.stateCondition.value
          })
        ]);
      });
      await Promise.all(promises);
      emit('close');
    };

    const { loading, exec: submit } = usePromise(handleSubmit, true);

    const properties = sourceId => {
      const source = sources.value.find(source => source.id === sourceId);

      if (source?.objectProperties) {
        return sortBy(source.objectProperties, 'humanReadable');
      }

      return [];
    };

    onBeforeMount(async () => {
      state.value.objectStates = (await statesService.fetch(props.objectId))
        .map(i => prepareObject(i))
        .filter(state => !state.stateIsDefault.value);

      sources.value = (await linkedObjectsService.fetch(props.objectId)).map(
        i => {
          return {
            ...i,
            objectProperties: i.objectProperties.map(property => {
              return {
                ...property,
                humanReadable:
                  i?.schema?.schemaProperties.find(
                    item => item.property === property.property
                  )?.description || property.property
              };
            })
          };
        }
      );

      state.value.objectStates.forEach(state => {
        const linkedPropertyId = state.stateValue?.linkedPropertyId;
        if (linkedPropertyId) {
          const source = sources.value.find(source =>
            source.objectProperties.some(
              property => property.id === linkedPropertyId
            )
          );
          if (source) {
            state.sourceId = source.id;
          }
        }
      });

      state.value.objectStates;
    });

    return {
      state,
      loading,
      submit,
      operators,
      sources,
      properties
    };
  }
};
</script>
